<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <MayorMediaSlider />
          <MayorMediaList />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import MayorMediaSlider from "@/views/mayor/mayor_media/components/MayorMediaSlider.vue";
import MayorMediaList from "@/views/mayor/mayor_media/components/MayorMediaList.vue";

export default {
  name: "MayorMediaPage",
  async asyncData({ store }) {
    await store.dispatch("GET_MAYOR_MEDIA_PAGE");
    await store.dispatch("GET_OPEN_MAP");
  },
  components: {
    MayorMediaSlider,
    MayorMediaList,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.filterData = null;
    this.$store.state.filterResponse = null;
    this.$store.state.filterList = [];
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/parts/mayor/index.styl"
@import "~@/styles/parts/swiper.styl"
@import "~@/styles/parts/mycity/poster/posters.styl"
@import "~@/styles/parts/home/poster.styl"
</style>
